const borderColors = {
    grey: "#e3e3e3",
    outerBorder: "#000", // blue/green/red/pink group
    topLeftYellow: "#fff",
    bottomRightYellow: "#878787",
    red: "red"
  };

  const borderColorsGrey = {
    background: "#e3e3e3",
    border: "#000", // blue/green/red/pink group
    highlight: "#fff",
    shadow: "#878787",
  };

  export const pixelBorderColorsBlue = {
    background: "#24c2d8",
    border: "#000",
    highlight: "#40eae0",
    shadow: "#4068b2",
  };

  export const pixelBorderColorsPurple = {
    background: "#af6ff6",
    border: "#000",
    highlight: "#ff94b4",
    shadow: "#7f58ea",
  };

  export const pixelBorderColorsGreen = {
    background: "#00a279",
    border: "#000",
    highlight: "#79e430",
    shadow: "#006a6d",
  };

  export const pixelBorderColorsOrange = {
    background: "#ffb02f",
    border: "#000",
    highlight: "#fff540",
    shadow: "#ff5d3b",
  };



  type pixelColors = {
    background: string,
    border: string, 
    highlight: string,
    shadow: string,
  }
// Define the size multiplier for scaling
const size = 3; // Adjust this value to scale the border

export const pixelWidth = `calc(100% - ${size * 10}px)`;
export const pixelMarginOffset = `${size * 5}px`;

export const pixelBackground = borderColors.grey;

  // Generate the box-shadow values dynamically
export const pixelBoxShadow = `
    /* Top-right corner (inner yellow) */
    ${-size * 3}px 0 ${borderColors.grey}, ${-size * 4}px 0 ${borderColors.topLeftYellow}, /* RIGHT EDGE */
    0 ${-size * 3}px ${borderColors.grey}, 0 ${-size * 4}px ${borderColors.topLeftYellow},
    ${-size * 2}px ${-size * 2}px ${borderColors.grey}, ${-size * 3}px ${-size * 2}px ${borderColors.topLeftYellow},
    ${-size}px ${-size * 2}px ${borderColors.grey}, ${-size * 2}px ${-size * 3}px ${borderColors.topLeftYellow},
    /* Top-left corner (inner yellow) */
    ${size * 3}px 0 ${borderColors.grey}, ${size * 4}px 0 ${borderColors.bottomRightYellow}, /* LEFT EDGE */
    0 ${-size * 3}px ${borderColors.grey}, 0 ${-size * 4}px ${borderColors.bottomRightYellow},
    ${size * 2}px ${-size * 2}px ${borderColors.grey}, ${size * 3}px ${-size * 2}px ${borderColors.topLeftYellow},
    ${size}px ${-size * 2}px ${borderColors.grey}, ${size * 2}px ${-size * 3}px ${borderColors.topLeftYellow},
    /* Bottom-left corner (inner yellow) */
    ${size * 3}px 0 ${borderColors.grey}, ${size * 4}px 0 ${borderColors.bottomRightYellow},
    0 ${size * 3}px ${borderColors.grey}, 0 ${size * 4}px ${borderColors.bottomRightYellow},
    ${size * 2}px ${size * 2}px ${borderColors.grey}, ${size * 3}px ${size * 2}px ${borderColors.bottomRightYellow},
    ${size}px ${size * 2}px ${borderColors.grey}, ${size * 2}px ${size * 3}px ${borderColors.bottomRightYellow},
    /* Bottom-right corner (inner yellow) */
    ${-size * 3}px 0 ${borderColors.grey}, ${-size * 4}px 0 ${borderColors.bottomRightYellow},
    0 ${size * 3}px ${borderColors.grey}, 0 ${size * 4}px ${borderColors.bottomRightYellow},
    ${-size * 2}px ${size * 2}px ${borderColors.grey}, ${-size * 3}px ${size * 2}px ${borderColors.bottomRightYellow},
    ${-size}px ${size * 2}px ${borderColors.grey}, ${-size * 2}px ${size * 3}px ${borderColors.bottomRightYellow},
    /* Top-right corner (outer) */
    ${-size * 4}px 0 ${borderColors.grey}, ${-size * 5}px 0 ${borderColors.outerBorder},
    0 ${-size * 4}px ${borderColors.grey}, 0 ${-size * 5}px ${borderColors.outerBorder},
    ${-size * 3}px ${-size * 2}px ${borderColors.grey}, ${-size * 4}px ${-size * 2}px ${borderColors.outerBorder},
    ${-size * 2}px ${-size * 3}px ${borderColors.grey}, ${-size * 3}px ${-size * 3}px ${borderColors.outerBorder},
    ${-size * 2}px ${-size * 3}px ${borderColors.grey}, ${-size * 2}px ${-size * 4}px ${borderColors.outerBorder},
    /* Top-left corner (outer) */
    ${size * 4}px 0 ${borderColors.grey}, ${size * 5}px 0 ${borderColors.outerBorder},
    0 ${-size * 4}px ${borderColors.grey}, 0 ${-size * 5}px ${borderColors.outerBorder},
    ${size * 3}px ${-size * 2}px ${borderColors.grey}, ${size * 4}px ${-size * 2}px ${borderColors.outerBorder},
    ${size * 2}px ${-size * 3}px ${borderColors.grey}, ${size * 3}px ${-size * 3}px ${borderColors.outerBorder},
    ${size * 2}px ${-size * 3}px ${borderColors.grey}, ${size * 2}px ${-size * 4}px ${borderColors.outerBorder},
    /* Bottom-left corner (outer) */
    ${size * 4}px 0 ${borderColors.grey}, ${size * 5}px 0 ${borderColors.outerBorder},
    0 ${size * 4}px ${borderColors.grey}, 0 ${size * 5}px ${borderColors.outerBorder},
    ${size * 3}px ${size * 2}px ${borderColors.grey}, ${size * 4}px ${size * 2}px ${borderColors.outerBorder},
    ${size * 2}px ${size * 3}px ${borderColors.grey}, ${size * 3}px ${size * 3}px ${borderColors.outerBorder},
    ${size * 2}px ${size * 3}px ${borderColors.grey}, ${size * 2}px ${size * 4}px ${borderColors.outerBorder},
    /* Bottom-right corner (outer) */
    ${-size * 4}px 0 ${borderColors.grey}, ${-size * 5}px 0 ${borderColors.outerBorder},
    0 ${size * 4}px ${borderColors.grey}, 0 ${size * 5}px ${borderColors.outerBorder},
    ${-size * 3}px ${size * 2}px ${borderColors.grey}, ${-size * 4}px ${size * 2}px ${borderColors.outerBorder},
    ${-size * 2}px ${size * 3}px ${borderColors.grey}, ${-size * 3}px ${size * 3}px ${borderColors.outerBorder},
    ${-size * 2}px ${size * 3}px ${borderColors.grey}, ${-size * 2}px ${size * 4}px ${borderColors.outerBorder}
  `;

  export const pixelBoxShadowFn = (colors: pixelColors) => `
    /* Top-right corner (inner yellow) */
    ${-size * 3}px 0 ${colors.background}, ${-size * 4}px 0 ${colors.highlight}, /* RIGHT EDGE */
    0 ${-size * 3}px ${colors.background}, 0 ${-size * 4}px ${colors.highlight},
    ${-size * 2}px ${-size * 2}px ${colors.background}, ${-size * 3}px ${-size * 2}px ${colors.highlight},
    ${-size}px ${-size * 2}px ${colors.background}, ${-size * 2}px ${-size * 3}px ${colors.highlight},
    /* Top-left corner (inner yellow) */
    ${size * 3}px 0 ${colors.background}, ${size * 4}px 0 ${colors.shadow}, /* LEFT EDGE */
    0 ${-size * 3}px ${colors.background}, 0 ${-size * 4}px ${colors.shadow},
    ${size * 2}px ${-size * 2}px ${colors.background}, ${size * 3}px ${-size * 2}px ${colors.highlight},
    ${size}px ${-size * 2}px ${colors.background}, ${size * 2}px ${-size * 3}px ${colors.highlight},
    /* Bottom-left corner (inner yellow) */
    ${size * 3}px 0 ${colors.background}, ${size * 4}px 0 ${colors.shadow},
    0 ${size * 3}px ${colors.background}, 0 ${size * 4}px ${colors.shadow},
    ${size * 2}px ${size * 2}px ${colors.background}, ${size * 3}px ${size * 2}px ${colors.shadow},
    ${size}px ${size * 2}px ${colors.background}, ${size * 2}px ${size * 3}px ${colors.shadow},
    /* Bottom-right corner (inner yellow) */
    ${-size * 3}px 0 ${colors.background}, ${-size * 4}px 0 ${colors.shadow},
    0 ${size * 3}px ${colors.background}, 0 ${size * 4}px ${colors.shadow},
    ${-size * 2}px ${size * 2}px ${colors.background}, ${-size * 3}px ${size * 2}px ${colors.shadow},
    ${-size}px ${size * 2}px ${colors.background}, ${-size * 2}px ${size * 3}px ${colors.shadow},
    /* Top-right corner (outer) */
    ${-size * 4}px 0 ${colors.background}, ${-size * 5}px 0 ${colors.border},
    0 ${-size * 4}px ${colors.background}, 0 ${-size * 5}px ${colors.border},
    ${-size * 3}px ${-size * 2}px ${colors.background}, ${-size * 4}px ${-size * 2}px ${colors.border},
    ${-size * 2}px ${-size * 3}px ${colors.background}, ${-size * 3}px ${-size * 3}px ${colors.border},
    ${-size * 2}px ${-size * 3}px ${colors.background}, ${-size * 2}px ${-size * 4}px ${colors.border},
    /* Top-left corner (outer) */
    ${size * 4}px 0 ${colors.background}, ${size * 5}px 0 ${colors.border},
    0 ${-size * 4}px ${colors.background}, 0 ${-size * 5}px ${colors.border},
    ${size * 3}px ${-size * 2}px ${colors.background}, ${size * 4}px ${-size * 2}px ${colors.border},
    ${size * 2}px ${-size * 3}px ${colors.background}, ${size * 3}px ${-size * 3}px ${colors.border},
    ${size * 2}px ${-size * 3}px ${colors.background}, ${size * 2}px ${-size * 4}px ${colors.border},
    /* Bottom-left corner (outer) */
    ${size * 4}px 0 ${colors.background}, ${size * 5}px 0 ${colors.border},
    0 ${size * 4}px ${colors.background}, 0 ${size * 5}px ${colors.border},
    ${size * 3}px ${size * 2}px ${colors.background}, ${size * 4}px ${size * 2}px ${colors.border},
    ${size * 2}px ${size * 3}px ${colors.background}, ${size * 3}px ${size * 3}px ${colors.border},
    ${size * 2}px ${size * 3}px ${colors.background}, ${size * 2}px ${size * 4}px ${colors.border},
    /* Bottom-right corner (outer) */
    ${-size * 4}px 0 ${colors.background}, ${-size * 5}px 0 ${colors.border},
    0 ${size * 4}px ${colors.background}, 0 ${size * 5}px ${colors.border},
    ${-size * 3}px ${size * 2}px ${colors.background}, ${-size * 4}px ${size * 2}px ${colors.border},
    ${-size * 2}px ${size * 3}px ${colors.background}, ${-size * 3}px ${size * 3}px ${colors.border},
    ${-size * 2}px ${size * 3}px ${colors.background}, ${-size * 2}px ${size * 4}px ${colors.border}
  `;


  type PixelBoxProps = {

    height?: string,
    width?: string,
    marginOffset?: boolean,
    noPaddingBottom?: boolean,
    margin?: string,
    colors?: pixelColors
  }

  export const PixelBox = (props: React.PropsWithChildren<PixelBoxProps>) => {
    return <div style={{ 
        height: props.height, 
        width: props.width || '100%', 
        margin: props.margin
    }}>
        <div style={{ 
                height: `calc(${props.height ? props.height : '100%'} - ${size * 10}px)`, 
                width: `calc(${props.width ? props.width : '100%'} - ${size * 10}px)`, 
                marginTop: props.marginOffset == true || props.marginOffset == undefined ? pixelMarginOffset : 0, 
                marginLeft: props.marginOffset == true || props.marginOffset == undefined ? pixelMarginOffset : 0, 
                paddingBottom: props.noPaddingBottom ? undefined : '8px',
                background: props.colors?.background || borderColorsGrey.background, 
                boxShadow: pixelBoxShadowFn(props.colors || borderColorsGrey)
            }}>
                
            {props.children}
        </div>
    </div>
  }