import { Link, Outlet, useLocation } from 'react-router-dom';
import styles from './skeleton.module.css';
import habbieLogoImage from '../ui/images/habbie_logo_02.png'
import tamagotchiImage from '../ui/images/tamagtochi_01.png'
import itemPrawn from './items/inv_prawn_1.png'
import itemSparkleBerry from './items/inv_berry_sparkle_1.png'
import itemCandyCane from './items/inv_candy_cane_1.png'
import itemCookieSparkle from './items/inv_cookie_sparkle_1.png'
import itemCookieXmas from './items/inv_cookie_xmas_1.png'
import itemFish from './items/inv_fish_1.png'
import itemHolly from './items/inv_holly_1.png'
import itemPavlova from './items/inv_pavlova_1.png'
import itemXmasCake from './items/inv_xmas_cake_1.png'

import { pixelBorderColorsBlue, pixelBorderColorsGreen, pixelBorderColorsOrange, pixelBorderColorsPurple, PixelBox, pixelBoxShadow, pixelMarginOffset, pixelWidth } from './pixelBorder';

export const Skeleton = () => {
  const location = useLocation();
  
  const showPixelBox = location.pathname !== '/';
  
  return (
    <div className={styles.pageOuterContainer}>
      <div className={styles.pageContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.logoContainer}>
              <img src={habbieLogoImage} className={styles.logo} alt="Habbie Logo" />
            </div>
          <PixelBox margin="0 0 0 0px" height="100px" noPaddingBottom={true} colors={pixelBorderColorsBlue}>
            <div className={styles.navContainer}>
                <Link to="/" className={`${styles.navItem} ${styles.homeNavImage}`}></Link>
                <Link to="/guides" className={`${styles.navItem} ${styles.scrollNavImage}`}></Link>
                <Link to="/world" className={`${styles.navItem} ${styles.worldNavImage}`}></Link>
                {/* <Link to="/privacy" className={`${styles.navItem} ${styles.settingsNavImage}`}></Link> */}
                {/* <Link to="/terms" className={styles.navItem}>Terms</Link> */}
              </div>
          </PixelBox>
        </div>
        <div className={styles.bodyContainer}>
          <div className={styles.leftBarContainer}>
              <PixelBox height="100%" colors={pixelBorderColorsGreen}>
                <div className={styles.leftBarContainerInner}>
                  <div className={styles.heading3}>
                    New Items!
                  </div>
                  <div className={styles.hoverAnimation}><img src={itemSparkleBerry} style={{ transform: `rotate(${Math.random() * 30}deg)`}} className={styles.leftBarImage} alt={"left bar image"}/></div>
                  <div className={styles.hoverAnimation}><img src={itemCookieSparkle} style={{ transform: `rotate(${Math.random() * 30}deg)`}}  className={styles.leftBarImage} alt={"left bar image"}/></div>
                  <div className={styles.hoverAnimation}><img src={itemPrawn} style={{ transform: `rotate(${Math.random() * 30}deg)`}}  className={styles.leftBarImage} alt={"left bar image"}/></div>
                  <div className={styles.hoverAnimation}><img src={itemFish} style={{ transform: `rotate(${Math.random() * 30}deg)`}}  className={styles.leftBarImage} alt={"left bar image"}/></div>
                  <div className={styles.hoverAnimation}><img src={itemCookieXmas} style={{ transform: `rotate(${Math.random() * 30}deg)`}}  className={styles.leftBarImage} alt={"left bar image"}/></div>
                  <div className={styles.hoverAnimation}><img src={itemXmasCake} style={{ transform: `rotate(${Math.random() * 30}deg)`}}  className={styles.leftBarImage} alt={"left bar image"}/></div>
                  <div className={styles.hoverAnimation}><img src={itemHolly} style={{ transform: `rotate(${Math.random() * 30}deg)`}}  className={styles.leftBarImage} alt={"left bar image"}/></div>
                  <div className={styles.hoverAnimation}><img src={itemCandyCane} style={{ transform: `rotate(${Math.random() * 30}deg)`}}  className={styles.leftBarImage} alt={"left bar image"}/></div>
                  <div className={styles.hoverAnimation}><img src={itemPavlova} style={{ transform: `rotate(${Math.random() * 30}deg)`}}  className={styles.leftBarImage} alt={"left bar image"}/></div>

                </div>
              </PixelBox>
          </div>
          
          <div className={styles.mainContentContainer}>
            {showPixelBox 
              ? <PixelBox height="100%" colors={pixelBorderColorsPurple}>
                <Outlet />
              </PixelBox>
              : <Outlet />
            }
          </div>

          <div className={styles.rightColumnContainer}>
            <div className={styles.socialLinksContainer}>
              <img src={tamagotchiImage} className={styles.tamagotchi} alt="Pixel art tamagotchi with social links inside" />
              <div className={styles.socialLinksPositioning}>
                <a 
                  href="https://instagram.com/lucythemaker"
                  target="_blank"
                  className={`${styles.tamagotchiLink} ${styles.instagramImage}`}
                  style={{
                    top: '0',
                    left: '0',
                  }}
                />
                <a 
                  href="https://tiktok.com/@lucythemaker"
                  target="_blank"
                  className={`${styles.tamagotchiLink} ${styles.tiktokImage}`}
                  style={{
                    top: '0',
                    right: '0',
                  }}
                />
                <a 
                  href="https://discord.gg/pQjvKA9Khq"
                  target="_blank"
                  className={`${styles.tamagotchiLink} ${styles.discordImage}`}
                  style={{
                    top: 'calc(50% - 20px)',
                    left: 'calc(50% - 20px)',
                  }}
                />
                <a 
                  href="https://www.youtube.com/@lucythemaker"
                  target="_blank"
                  className={`${styles.tamagotchiLink} ${styles.youtubeImage}`}
                  style={{
                    bottom: '0',
                    left: '0',
                  }}
                />
                <a 
                  href="https://bsky.app/profile/did:plc:wt6bhxl7tcryhwxrwdgig3s2"
                  target="_blank"
                  className={`${styles.tamagotchiLink} ${styles.blueskyImage}`}
                  style={{
                    bottom: '0',
                    right: '0',
                  }}
                />
              </div>
            </div>
            <div className={styles.rightBarContainer}>
            <PixelBox margin={'0 0 16px 0'} colors={pixelBorderColorsBlue}>
                <p><b>News!</b> - 01/01/2025</p>
                <p>HAPPY NEW YEARS! We hope you love the sparkle themed food & decor 💖</p>
            
              </PixelBox>
              
              <PixelBox margin={'0 0 16px 0'} colors={pixelBorderColorsBlue}>
                <p><b>News!</b> - 09/12/2024</p>
                <p>Merry Christmas! Lots of new holday themed food items and backgrounds have been added in the latest update! Be sure to check it out!</p>
            
              </PixelBox>

              <PixelBox margin={'0 0 16px 0'} colors={pixelBorderColorsBlue}>
                <p><b>News!</b> - 01/12/2024</p>
                <p>Coins Coins Coins! You wanted more control over what items you had to give to your pets, so we have put in a Kiosk to allow you to purchase items using the coins you get in your daily gifts! Don't worry no need to spend any real money 💸</p>
            
              </PixelBox>
            </div>
          </div>
          
        </div>

          <div className={styles.footerContainer}>
            <PixelBox colors={pixelBorderColorsOrange} height={"100px"}>
              <div className={styles.footerContainerInner}>
                <p>&copy; Selexin 2025 </p>
                <p>Made by @lucythemaker and @selexin</p>

                <p>
                  <Link to='terms'>Terms</Link> -  
                  <Link to='privacy'> Privacy Policy</Link>
                </p>
              </div>
            </PixelBox>
          </div>
      </div>
    </div>
  );
}