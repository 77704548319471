import styles from './home.module.css';
import Screenshot01 from '../ui/images/habbie_mockup_01.png'
import Screenshot02 from '../ui/images/habbie_mockup_02.png'
import Screenshot03 from '../ui/images/habbie_mockup_03.png'
import Screenshot04 from '../ui/images/habbie_mockup_04.png'
import AppStore from '../ui/images/appstore.svg'
import Discord from '../ui/images/discord.png'
import { pixelBorderColorsPurple, PixelBox } from "../ui/pixelBorder";

export default function Home2() {
    
  return (
    <>
            <PixelBox margin={'0 0 16px 0'} colors={pixelBorderColorsPurple}>

            <div style={{ width: '100%', textAlign: 'center',}}>
                <div className={styles.heading1}>
                    Habbie
                </div>
                <div className={styles.subHeading}>
                    Take care of your Habbie
                    <br />
                    & form your real life habits!
                </div>
                <div className={styles.downloadButton}>
                    <a href="https://apps.apple.com/app/apple-store/id6468639991?pt=122400551&ct=website&mt=8" 
                        style={{
                            display: "inline-block",
                            overflow: "hidden",
                            borderRadius: "13px",
                            width: "250px",
                            height: "83px",
                        }}
                        target="_blank"
                    >
                            <img src={AppStore} alt="Download on the App Store" 
                                style={{
                                    borderRadius: "13px",
                                    width: "250px",
                                    height: "83px"
                                }}
                            />    
                    </a>    
                    <p>It's Free!</p>
                </div>
            </div>
            </PixelBox>
            <PixelBox margin={'0 0 16px 0'}  colors={pixelBorderColorsPurple}>
                <div className={styles.section}>
                    <div className={styles.heading3}>
                        Meet Habbie, Your Retro-Style Habit Sidekick!
                    </div>
                    <p>
                        The concept is simple: you can feed your Habbie Pet by hitting your daily goals. The more you achieve, the happier and healthier your pet becomes. It's a win-win situation!
                    </p>
                    <br />

                    <div className={styles.sectionImageContainer}>
                        <div className={styles.hoverAnimation}><img src={Screenshot01} className={styles.sectionImage}  alt={""}/></div>
                        <div className={styles.hoverAnimation}><img src={Screenshot02} className={styles.sectionImage}  alt={""}/></div>
                        <div className={styles.hoverAnimation}><img src={Screenshot03} className={styles.sectionImage}  alt={""}/></div>
                    </div>
                    

                </div>
            </PixelBox>
            <PixelBox margin={'0 0 16px 0'}  colors={pixelBorderColorsPurple}>
                <div className={styles.section}>
                    <div className={styles.heading3}>
                        Key Features:
                    </div>
                    <div className={styles.splitSection}>
                        <div className={styles.hoverAnimation}><img src={Screenshot04} className={styles.sectionImage}  alt={""}/></div>
                        <ul>
                            <li><b>Adorable Virtual Pet:</b> Watch your Habbie grow, play, and express emotions as you achieve your daily goals.</li>
                            <li><b>Daily Rewards:</b> Every day you get to unbox a reward for hitting your goal.</li>
                            <li><b>Keep you accountable:</b> If you miss your goal, your pet will leave a poop on the screen, which can only be cleaned up by hitting your goal the next day.</li>
                            <li><b>Track Progress:</b> Monitor your goals, achievements, and pet's health with easy-to-read statistics.</li>
                            <li><b>Kiosk:</b> Gain coins daily by hitting your goals and you can buy decor, food and items from the kiosk.</li>
                            <li><b>Customisation:</b> Customise your world with a variety of backgrounds to collect and choose from.</li>
                            <li><b>Collect them all:</b> Collect all the habbies and expand the habits you are tracking.</li>
                        </ul>
                    </div>

                </div>

            </PixelBox>


            <PixelBox margin={'0 0 16px 0'}  colors={pixelBorderColorsPurple}>
                <div style={{textAlign: "center"}}>
                    <div className={styles.heading3}>Get Help</div>
                    <p style={{textAlign: "center"}}>The best way to get help is via the Habbie Discord!</p>
                    <div>
                        <a href="https://discord.gg/pQjvKA9Khq" 
                            style={{
                                display: "inline-block",
                                overflow: "hidden",
                                borderRadius: "13px",
                                border: "4px solid #5965f2",
                                padding: "10px 30px" ,
                                textDecoration: "none",
                                textAlign: "center",
                                marginTop: 20,
                                marginBottom: 20,
                                backgroundColor: '#e9f2ff',
                            }}
                            target="_blank"
                            rel="noreferrer"
                        >
                                <img src={Discord} alt="Download on the App Store" 
                                    style={{
                                        borderRadius: "13px",
                                        width: "40px",
                                        display: "inline-block",
                                        margin: "auto",
                                    }}
                                />    
                                <p style={{
                                        color: "#5965f2",
                                        textDecoration: "none"
                                    }}>
                                Join us on Discord
                                </p>
                                
                        </a>
                    </div>

                    <p style={{textAlign: "center", marginBottom: 15}}>Alternatively you can email support@habbie.app.</p>
                    <p style={{textAlign: "center"}}>We're here to help you on your journey to a healthier and happier lifestyle with your Habbie!</p>
                </div>
            </PixelBox>
    </>
  );
}
